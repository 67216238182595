@font-face {
  font-family: SfPro;
  src: url(../public/fonts/SFPRODISPLAYREGULAR.OTF);
  font-weight: 400;
}

@font-face {
  font-family: SfPro;
  src: url(../public/fonts/SFPRODISPLAYMEDIUM.OTF);
  font-weight: 500;
}

@font-face {
  font-family: SfPro;
  src: url(../public/fonts/SFPRODISPLAYBOLD.OTF);
  font-weight: bold;
}

body {
  font-family: SfPro;
  margin: 0;
  background: #000000;
}

a {
  text-decoration: none;
}

.headline {
  font-size: 28px;
  font-weight: bold;
}

* {
  direction: ltr;
  color: #ffffff;
}

.link {
  display: flex;
  align-items: center;
  width: fit-content;
  height: fit-content;
  color: #0066ff;
}

.link .icon {
  margin-left: 4px;
}

.page {
  padding: 0px 148px;
  padding-top: 32px;
}

.header {
  background: #000000;
  font-size: 15px;
  color: #ffffff;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 18px 148px;
}

.header .logo {
  width: 24px;
}

.header .menu {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.header .menu .item {
  margin-right: 34px;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: 0.2s ease-in;
  font-size: 16px;
  color: #ffffff;
}

.header .menu .item:hover {
  opacity: 0.6;
}

.head .menu .item .icon {
  margin-right: 8px;
}

.head .menu .item:last-child {
  margin-right: 0px;
}

.home .slider .item {
  display: flex;
  align-items: center;
}

.home .slider .item img {
  border-radius: 14px;
  border: 1px solid gray;
  width: 148px;
}

.language .languages {
  margin-top: 24px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.language .languages .item {
  font-size: 21px;
  font-weight: bold;
  cursor: pointer;
  width: 33.3%;
  margin-bottom: 32px;
  opacity: 0.5;
}

.language .languages .active {
  opacity: 1;
}

.home .onboard {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 48px 32px;
  position: relative;
  overflow: hidden;
}

.home .onboard .title {
  font-size: 42px;
  color: #ffffff;
  font-weight: 500;
}

.home .onboard .cap {
  width: 568px;
  text-align: center;
  margin-top: 24px;

}

.home .onboard .bg {
  width: 135%;
  position: absolute;
  z-index: -1;
  height: 100%;
  bottom: 0px;
  opacity: .3;
}

.home .onboard .caption {
  font-size: 24px;
  background: -webkit-linear-gradient(#828282, #eee);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  color: #ffffff;
  text-align: center;
  line-height: 32px;
}

.page-contact {
  display: flex;
  flex-direction: column;
}

.page-contact .headline {
  font-size: 24px;
}

.button {
  padding: 12px 22px;
  background: #0066ff;
  border-radius: 12px;
  width: fit-content;
  cursor: pointer;
  display: flex;
  justify-content: center;
  min-width: 128px;
}

.button:hover {
  background: #0055d3;
}